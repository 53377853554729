import React from 'react';
import './index.css';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'
import * as serviceWorker from './serviceWorker';
import { v4 as uuidv4 } from 'uuid';
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache';
import { createRoot } from 'react-dom/client';

const nonce = btoa(uuidv4())

const cache = createCache({
    key: 'entauth',
    nonce: nonce,
    prepend: true
})

const root = createRoot(document.getElementById('root'));

if (window.self === window.top) {
    root.render(
        <React.StrictMode>
            <I18nextProvider i18n={i18n}>
                <CacheProvider value={cache}>
                    <App />
                </CacheProvider>
            </I18nextProvider>
        </React.StrictMode>,
    );
} else {
    root.render(<p>Unable to load application, please refresh browser tab.</p>);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
