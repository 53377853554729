import React, { Suspense } from 'react';
import { Box } from '@mui/material';
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';
import TopProgressBar from '../basics/TopProgressBar';

const ConfirmForgotPassword = React.lazy(() => import('../pages/ConfirmForgotPassword'))
const ForgotPassword = React.lazy(() => import('../pages/ForgotPassword'))
const Logout = React.lazy(() => import('../pages/Logout'))
const SignIn = React.lazy(() => import('../pages/SignIn'))
const SignUp = React.lazy(() => import('../pages/SignUp'))

export default function AppRoutes() {
    return (
        <Box sx={{position: 'absolute', minHeight: '100%', width: '100%'}}>
            <BrowserRouter>
                <Suspense fallback={<TopProgressBar />}>
                    <Routes>
                        <Route path="/" element={<SignIn />} />
                        <Route path="/login" element={<SignIn />} />
                        <Route path="/signup" element={<SignUp />} />
                        <Route path='/verifyemail' element={<SignUp />} />
                        <Route path='/verifyphonenumber' element={<SignUp />} />
                        <Route path='/forgotpassword' element={<ForgotPassword eventMode='forgotPassword' />} />
                        <Route path='/resetpassword' element={<ForgotPassword eventMode='resetPassword' />} />
                        <Route path='/confirmforgotpassword' element={<ConfirmForgotPassword />} />
                        <Route path='/logout' element={<Logout />} />
                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                </Suspense>
            </BrowserRouter>
        </Box>
    )
}
