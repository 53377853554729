import config from './config.json';
import SHA1 from 'crypto-js/sha1';
import jwt_decode from 'jwt-decode'
import i18n from './i18n'
import axios from  'axios'

// Use dummy function as default
export default function dummy() {
    console.log('Hello')
}

export function emailValid (eml) {
    return /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+[A-Za-z]+$/.test(eml);
}

export function phoneValid (value) {
    return /^[+][0-9]{5,15}$/.test(value);
}

export function urlValid(url) {
    return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(url);
}

export class passwordRequirements {
    constructor(password, confirmPassword, passwordPwned, setPasswordPwned) {
        this.password = password
        this.confirmPassword = confirmPassword
        this.passwordPwned = passwordPwned
        this.setPasswordPwned = setPasswordPwned
        /**
         *  NOTE passwordPwned states:
         *
         *  null = haven't tested password yet
         *  undefined = currently checking password
         *  true = password has been checked and is pwn'd (bad)
         *  false = password has been checked and is not pwn'd (good)
        */
    }

    lengthMet = () => {
        return this.password.length >= 10
    }

    containsUppercase = () => {
        return this.password.match(/[A-Z]/) !== null;
    }

    containsLowercase = () => {
        return this.password.match(/[a-z]/) !== null;
    }

    containsNumber = () => {
        return this.password.match(/[0-9]/) !== null;
    }

    containsSpecial = () => {
        return this.password.match(/[$^*.[\]{}()?"!@#%&/\\,><':;|_~`]/) !== null;
    }

    passwordsMatch = () => {
        return this.password.length > 0 && this.password === this.confirmPassword
    }

    checkPwnedPasswords = () => {
        this.setPasswordPwned(undefined)

        let passwordHash = SHA1(this.password).toString().toUpperCase()
        let hashPrefix = passwordHash.substring(0, 5)
        axios.get('https://api.pwnedpasswords.com/range/' + hashPrefix)
        .then((res) => {
            let hashSuffixes = res.data.split("\r\n")
            this.setPasswordPwned(hashSuffixes.some((elem) => {
                let hashSuffix, numBreaches
                [hashSuffix, numBreaches] = elem.split(':')
                let hash = (hashPrefix + hashSuffix).toUpperCase()
                if (hash === passwordHash) {
                    console.log("Password has been breached, has appeared " + numBreaches + " times")
                    return true
                }

                return false
            }))
        })
        .catch((err) => {
            console.error("error response from pwnedpasswords.com:")
            console.error(err)
            this.setPasswordPwned(null)
        })
    }

    allPasswordRequirementsMet = () => {
        let staticPasswordRequirementsMet = this.lengthMet() &&
                                            this.containsUppercase() &&
                                            this.containsLowercase() &&
                                            this.containsNumber() &&
                                            this.containsSpecial() &&
                                            this.passwordsMatch()

        if (staticPasswordRequirementsMet && this.passwordPwned === null) {
            this.checkPwnedPasswords()
        }

        return staticPasswordRequirementsMet && this.passwordPwned === false
    }
}

export function getAccessToken() {
    return localStorage.getItem('AccessToken') || sessionStorage.getItem('AccessToken')
}

export function getSessionId() {
    return localStorage.getItem('session_id')
}

export function setLanguageCode(language) {
    console.log("Problem here")
    var langCode = language || 'en'
    if (config.ui_languages[language] === undefined) {
        langCode = 'en'
    }
    localStorage.setItem('i18next', langCode)
    i18n.changeLanguage(langCode)
}

export function getLanguageCode() {
    return localStorage.getItem('i18next') || 'en'
}

export function getIdToken() {
    return localStorage.getItem('IdToken') || sessionStorage.getItem('IdToken')
}

export function getRefreshToken() {
    return localStorage.getItem('RefreshToken') || sessionStorage.getItem('RefreshToken')
}

export function clearAccessToken() {
    localStorage.removeItem('AccessToken')
    sessionStorage.removeItem('AccessToken')
}

export function clearIdToken() {
    localStorage.removeItem('IdToken')
    sessionStorage.removeItem('IdToken')
}

export function clearRefreshToken() {
    localStorage.removeItem('RefreshToken')
    sessionStorage.removeItem('RefreshToken')
}

export function clearAllTokens() {
    clearAccessToken()
    clearIdToken()
    clearRefreshToken()
}

export function tokensExpiredOrMissing() {
    let tokens = [getAccessToken(), getIdToken()]
    for (let i in tokens) {
        let token = tokens[i]
        if (!token) {
            return true
        }

        let decodedToken = jwt_decode(token)
        let expiration = decodedToken.exp * 1000 // convert seconds to milliseconds
        if (expiration < Date.now()) {
            return true
        }
    }

    return false
}

export function getURLQueryParam(paramName) {
    const queryParams = new URLSearchParams(window.location.search)
    return queryParams.get(paramName)
}

export function maintainParameters(includeQuestionMark=true) {
    var parameter_string = includeQuestionMark ? "?" : ""
    if (getURLQueryParam('destination')) {
        parameter_string += "&destination=" + getURLQueryParam('destination')
    }
    if (getURLQueryParam('targetapplication')) {
        parameter_string += "&targetapplication=" + getURLQueryParam('targetapplication')
    }
    return parameter_string
}

export function isApprovedRedirectTarget(uri) {
    try {
        var parsed = new URL(uri)
        for (var domain in config.approved_redirect_domains) {
            if (parsed.hostname.endsWith(config.approved_redirect_domains[domain])) {
                return true
            }
        }
        console.log("this domain is not included in the approved list of redirect targets")
    } catch (error) {
        console.log("error with isApprovedRedirectTarget: " + error.toString())
    }
    return false
}

export function getTargetApp() {
    return (getURLQueryParam('targetapplication') || getURLQueryParam('client_id') || '_useracctmgmt').toLowerCase()
}

const customAppHeaders = {
    crc: 'Customer Resource Center',
    essentials: 'Customer Resource Center',
    marketplace: 'Marketplace',
    velis: 'Singu FM'
}

export function needsCustomAppHeader() {
    const targetApplication = localStorage.getItem('target_application')
    return Object.keys(customAppHeaders).some((prefix) => { return targetApplication.startsWith(prefix) })
}

export function customAppHeader() {
    const targetApplication = localStorage.getItem('target_application')
    for (let prefix in customAppHeaders) {
      if (targetApplication.startsWith(prefix)) {
        return customAppHeaders[prefix].toUpperCase()
      }
    }
    return ''
}

export function getUsername() {
    let idToken = getIdToken()
    if (idToken) {
        let decodedToken = jwt_decode(idToken)
        return decodedToken.sub
    }

    return localStorage.getItem('username')
}

export function getEmail() {
    let emailParam = getURLQueryParam('email')
    if (emailParam !== null) {
        return decodeURIComponent(emailParam)
     }

    let idToken = getIdToken()
    if (idToken) {
        let decodedToken = jwt_decode(idToken)
        return decodedToken.email
    }

    return localStorage.getItem('email') || ''
}
