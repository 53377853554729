import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AppTheme from './themes/AppTheme';
import { Amplify } from 'aws-amplify';
import config from './config.json';

import Routes from './components/Routes'
import { getURLQueryParam, setLanguageCode } from './utils'

const theme = createTheme(AppTheme);

Amplify.configure({
  Auth: {
    Cognito: {
          userPoolId: config.cognito_user_pool_id,
          userPoolClientId: config.default_cognito_client_id
        }
    }
});

export default function App() {
    React.useEffect(() => {
        const languageCode = getURLQueryParam('language')
        if (languageCode) {
            setLanguageCode(languageCode)
            const url = new URL(window.location.href)
            const queryParams = new URLSearchParams(window.location.search)
            queryParams.delete('language')
            url.search = queryParams.toString()
            window.location.href = url.href
        }
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <Routes />
        </ThemeProvider>
    );
}
